import React from 'react'

import css from './YouPick.module.css';
import classNames from 'classnames';
import NamedLink from '../NamedLink/NamedLink';


export default function YouPick(props) {
  const { rootClassName, className, intl } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.youPick}>
          <h2 className={css.youPickTitle}>
            Don't let your work define your location. <br /> <span> You pick! </span>
          </h2>
        <div className={css.youPickCardContainer}>
          <NamedLink name="SearchPage" to={{ search: "?pub_category=beach"}} className={css.youPickCardBeach}>
            <div className={css.youPickEmpty}>
            </div>
            <div className={css.youPickCardFooter}>
              Beach
            </div>
          </NamedLink>
          <NamedLink name="SearchPage" to={{ search: "?pub_category=mountain"}} className={css.youPickCardMountain}>
            <div className={css.youPickEmpty}>
            </div>
            <div className={css.youPickCardFooter}>
              Mountain
            </div>
          </NamedLink>
          <NamedLink name="SearchPage" to={{ search: "?pub_category=city"}} className={css.youPickCardCity}>
            <div className={css.youPickEmpty}>
            </div>
            <div className={css.youPickCardFooter}>
              City
            </div>
          </NamedLink>
          <NamedLink name="SearchPage" to={{ search: "?pub_category=countryside"}} className={css.youPickCardCountrySide}>
            <div className={css.youPickEmpty}>
            </div>
            <div className={css.youPickCardFooter}>
              Countryside
            </div>
          </NamedLink>
        </div>
      </div>
    </div>
  )
}

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingPricingForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';

import css from './EditListingPricingPanel.module.css';

const { Money } = sdkTypes;

const EditListingPricingPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    backButtonText,
    redirectAfterPrevTab,
    ...rest
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { price, publicData } = currentListing.attributes;
  const { cleaningFee, monthlyDiscount, weeklyDiscount, minimumStay, weekendPrice } = publicData || {};

  const panelTitle = (
    <FormattedMessage id="EditListingPricingPanel.title" />
  );

  const isDraft = currentListing?.id && currentListing?.attributes?.state === LISTING_STATE_DRAFT;

  const priceCurrencyValid = price instanceof Money ? price.currency === config.currency : true;

  const cleaningFeeMaybe = cleaningFee ? {cleaningFee: new Money(cleaningFee?.amount || null, cleaningFee?.currency)} : {}
  const weekendPriceMaybe = weekendPrice ? {weekendPrice: new Money(weekendPrice?.amount || null, weekendPrice?.currency)} : {}
  
  const form = priceCurrencyValid ? (
    <EditListingPricingForm
      className={css.form}
      initialValues={{ price, monthlyDiscount, weeklyDiscount, minimumStay, ...cleaningFeeMaybe, ...weekendPriceMaybe}}
      onSubmit={(values) => {
        const { price, monthlyDiscount, weeklyDiscount, minimumStay, cleaningFee, weekendPrice } = values;
        const updateValues = {
          price,
          publicData: {
            cleaningFee: cleaningFee ? { amount: cleaningFee.amount, currency: config.currency } : null,
            monthlyDiscount,
            weeklyDiscount,
            weekendPrice: weekendPrice ? { amount: weekendPrice.amount, currency: config.currency } : null,
            minimumStay: parseInt(minimumStay)
          }
        };
        onSubmit(updateValues);
      }}
      onChange={onChange}
      saveActionMsg={submitButtonText}
      disabled={disabled}
      ready={ready}
      updated={panelUpdated}
      updateInProgress={updateInProgress}
      fetchErrors={errors}
      backButtonText={backButtonText}
      redirectAfterPrevTab={redirectAfterPrevTab}
      isDraft={isDraft}
      {...rest}
    />
  ) : (
    <div className={css.priceCurrencyInvalid}>
      <FormattedMessage id="EditListingPricingPanel.listingPriceCurrencyInvalid" />
    </div>
  );

  return (
    <div className={classes}>
      <div className={css.header}>
        <h1 className={css.title}>{panelTitle}</h1>
        <p className={css.number}>
          07
        </p>
      </div>
      {form}
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingPricingPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingPricingPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingPricingPanel;

import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import _ from 'lodash';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';

import { Form, PrimaryButton as Button, FieldTextInput, FieldCheckbox } from '../../components';

import css from './EditListingPoliciesForm.module.css';

export const EditListingPoliciesFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        backButtonText,
        redirectAfterPrevTab,
        isNewListingFlow,
        backToListing,
        backToListingText,
      } = formRenderProps;

      const descriptionMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.descriptionMessage',
      });

      const secondDescriptionMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.secondDescriptionMessage',
      });

      const thirdDescriptionMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.thirdDescriptionMessage',
      });

      const rulesLabelMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.rulesLabel',
      });
      const rulesRequired = intl.formatMessage({
        id: 'EditListingPoliciesForm.rulesRequired',
      });
      const rulesPlaceholderMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.rulesPlaceholder',
      });

      const wifiNameLabelMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.wifiNameLabel',
      });
      const wifiNamePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.wifiNamePlaceholder',
      });

      const wifiPasswordLabelMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.wifiPasswordLabel',
      });
      const wifiPasswordPlaceholderMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.wifiPasswordPlaceholder',
      });

      const checkInLabelMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.checkInCheckOutLabel',
      });
      const checkInPlaceholderMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.checkInCheckOutPlaceholder',
      });
      const checkInCheckOutHelperText = intl.formatMessage({
        id: 'EditListingPoliciesForm.checkInCheckOutHelperText',
      });

      const directionsLabelMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.directionsLabel',
      });
      const directionsPlaceholderMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.directionsPlaceholder',
      });
      const directionsHelperText = intl.formatMessage({
        id: 'EditListingPoliciesForm.directionsHelperText',
      });

      const parkingLabelMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.parkingLabel',
      });
      const parkingPlaceholderMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.parkingPlaceholder',
      });
      const parkingHelperText = intl.formatMessage({
        id: 'EditListingPoliciesForm.parkingHelperText',
      });

      const swapLabelMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.swapLabel',
      });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPoliciesForm.updateFailed" />
        </p>
      ) : null;
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPoliciesForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>

          <p className={css.description}>
            {descriptionMessage}
          </p>

          <p className={css.description}>
            {secondDescriptionMessage}
          </p>

          <p className={css.description}>
            {thirdDescriptionMessage}
          </p>

          {errorMessage}
          {errorMessageShowListing}

          <FieldTextInput
            id="rules"
            name="rules"
            className={css.policy}
            inputRootClass={css.inputRoot}
            type="textarea"
            placeholder={rulesLabelMessage}
            helperText={rulesPlaceholderMessage}
            rows={3}
          />

          <div className={css.twoColumns}>
            <FieldTextInput
              id="wifiName"
              name="wifiName"
              className={css.input}
              inputRootClass={css.inputRoot}
              type="text"
              placeholder={wifiNameLabelMessage}
            />

            <FieldTextInput
              id="wifiPassword"
              name="wifiPassword"
              className={css.input}
              inputRootClass={css.inputRoot}
              type="text"
              placeholder={wifiPasswordLabelMessage}
            />
          </div>

          <FieldTextInput
            id="checkInCheckOut"
            name="checkInCheckOut"
            className={css.policy}
            inputRootClass={css.inputRoot}
            type="textarea"
            placeholder={checkInPlaceholderMessage}
            helperText={checkInCheckOutHelperText}
            rows={3}
          />

          <FieldTextInput
            id="directions"
            name="directions"
            className={css.policy}
            inputRootClass={css.inputRoot}
            type="textarea"
            placeholder={directionsPlaceholderMessage}
            helperText={directionsHelperText}
            rows={3}
          />

          <FieldTextInput
            id="parking"
            name="parking"
            className={css.policy}
            inputRootClass={css.inputRoot}
            type="textarea"
            placeholder={parkingPlaceholderMessage}
            helperText={parkingHelperText}
            rows={3}
          />

          <FieldCheckbox
            id="swap"
            name="swap"
            label={swapLabelMessage}
            className={classNames(css.policy, css.hide)}
            switchIcon={true}
          />

          <div className={css.buttonWrapper}>
            <Button
              className={css.backButton}
              type="button"
              inProgress={submitInProgress}
              onClick={redirectAfterPrevTab}
            >
              {backButtonText}
            </Button>
            <div className={css.buttonWrapper}>
              {!isNewListingFlow && (
                <Button
                  className={css.listingButton}
                  type="button"
                  inProgress={submitInProgress}
                  onClick={backToListing}
                >
                  {backToListingText}
                </Button>
              )}
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                ready={submitReady}
              >
                {saveActionMsg}
              </Button>
            </div>
          </div>
        </Form>
      );
    }}
  />
);

EditListingPoliciesFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

EditListingPoliciesFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPoliciesFormComponent);
